import { render, staticRenderFns } from "./MarketingTools_coupon_add.vue?vue&type=template&id=6b579a88&scoped=true&"
import script from "./MarketingTools_coupon_add.vue?vue&type=script&lang=js&"
export * from "./MarketingTools_coupon_add.vue?vue&type=script&lang=js&"
import style0 from "./MarketingTools_coupon_add.vue?vue&type=style&index=0&id=6b579a88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b579a88",
  null
  
)

export default component.exports