<template>
    <div id="MarketingTools_coupon_add">
        <div class="MarketingTools_coupon_add_title">{{ title }}</div>
        <div class="content">
            <div class="content_title">活动基本信息</div>
            <div class="content_content">
                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item label="优惠券名称">
                        <el-input v-model="form.name" size="small" style="width: 560px;"></el-input>
                    </el-form-item>
                    <el-form-item label="优惠券类别">
                        <el-radio-group v-model="form.type">
                            <el-radio :label="item.value" v-for="item in type_options" :key="item.value">{{ item.label
                                }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="现金额度" v-if="form.type == 1">
                        <el-input v-model="form.rule.amount" size="small" style="width: 96px;"></el-input>
                    </el-form-item>
                    <el-form-item label="满减额度" v-if="form.type == 2">
                        满
                        <el-input v-model="form.rule.full_discount.full" size="small"
                            style="width: 96px;margin: 0 10px;"></el-input>
                        减
                        <el-input v-model="form.rule.full_discount.discount" size="small"
                            style="width: 96px;margin: 0 10px;"></el-input>
                    </el-form-item>
                    <el-form-item label="折扣力度" v-if="form.type == 3">
                        <el-input v-model="form.rule.discount" size="small" style="width: 96px;margin-right: 10px;"
                            placeholder="0-1"></el-input> 折
                    </el-form-item>
                    <el-form-item label="发行数量">
                        <el-input v-model="form.num" size="small" style="width: 96px;margin-right: 10px;"></el-input>张
                    </el-form-item>
                    <el-form-item label="使用范围">
                        <el-radio-group v-model="form.scope">
                            <el-radio :label="item.value" v-for="item in scope_options" :key="item.value">{{ item.label
                                }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="领取方式">
                        <el-radio-group v-model="form.receive_type">
                            <el-radio :label="1">系统发放</el-radio>
                            <el-radio :label="2">用户领取</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="使用类型">
                        <el-radio-group v-model="form.receive_user_type">
                            <el-radio :label="item.value" v-for="item in receive_user_type_options" :key="item.value">{{
            item.label }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="领取规则">
                        <div>最低会员级别
                            <el-select v-model="form.receive_rule.min_level" placeholder=" "
                                style="width: 88px;margin: 0 10px;" size="small">
                                <el-option :label="item.name" :value="item.id" v-for="item in memberList"
                                    :key="item.id"></el-option>
                            </el-select>
                        </div>
                        <div>
                            可领数量
                            <el-input v-model="form.receive_rule.max_take_num" size="small"
                                style="width: 88px;margin: 0 10px;"></el-input>张
                        </div>
                    </el-form-item>
                    <el-form-item label="有效期设置">
                        <div>
                            <el-radio :label="1" v-model="form.validity_type">领取时间</el-radio>
                            领取后<el-input v-model="form.validity_rule.after_receive_day" size="small"
                                style="width: 88px;margin:0 10px;" :disabled="form.validity_type != 1"></el-input>天有效
                        </div>
                        <div>
                            <el-radio :label="2" v-model="form.validity_type">指定时间</el-radio>
                            <el-date-picker :disabled="form.validity_type != 2" v-model="validity_rule_times"
                                style="width: 201px;" size="small" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <el-radio :label="3" v-model="form.validity_type">活动时间</el-radio>
                    </el-form-item>
                    <el-form-item label="活动时间">
                        <el-date-picker v-model="times" style="width: 305px;" size="small" type="daterange"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="使用规则">
                        <div>
                            <span style="margin-right: 20px;">是否叠加</span>
                            <el-radio :label="1" v-model="form.use_rule.is_sckable">是</el-radio>
                            <el-radio :label="0" v-model="form.use_rule.is_sckable">否</el-radio>
                        </div>
                        <div>
                            最低使用金额
                            <el-input v-model="form.use_rule.is_limit_min_price" size="small"
                                style="width: 88px;"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch v-model="status"></el-switch>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content_title">适用范围 </div>
            <div class="content_content">
                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item label="适用范围">
                        <el-radio-group v-model="scope_hotel">
                            <el-radio :label="1">指定酒店</el-radio>
                            <el-radio :label="2">全部酒店</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="适用酒店" v-if="scope_hotel == 1">
                        <div>
                            <div>
                                <!-- <el-input v-model="search_hotelname" size="small"
                                    style="width: 160px; margin-right: 10px;" placeholder="酒店名称"></el-input>
                                <el-button type="primary" size="small">查询</el-button> -->
                                <el-button type="primary" size="small" @click="openAddHotel">添加酒店</el-button>
                            </div>
                            <div style="margin-top: 20px;">
                                <el-table :data="tableData" style="width: 100%" :max-height="500"
                                    :header-cell-style="{ background: '#F8FBFF' }">
                                    <el-table-column prop="name" label="主图" align="center">
                                        <template slot-scope="scope">
                                            <div>
                                                <img :src="scope.row.hotel_pic" style="width: 40px;height: 40px;" />
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="name" label="酒店名称" align="center"></el-table-column>
                                    <el-table-column prop="equity_id" label="操作" align="center">
                                        <template slot-scope="scope">
                                            <div>
                                                <el-button type="text" size="small"
                                                    @click="handle_remove_hotel(scope.row, scope.$index)">移除</el-button>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="bottom">
            <el-button style="width: 120px;">取消</el-button>
            <el-button type="primary" style="width: 120px;" @click="confirm_created">
                {{ editId ? '确认编辑' : '创建' }}
            </el-button>
        </div>
        <el-dialog title="添加酒店" :visible.sync="isShow_Addhotel">
            <div class="Addhotel_dialog_top ">
                <el-checkbox v-model="checked">只显示上架酒店</el-checkbox>
                <div>售价：
                    <el-input v-model="price_min" size="small" style="width: 88px;"></el-input>-
                    <el-input v-model="price_max" size="small" style="width: 88px;"></el-input>
                </div>
                <el-input v-model="search_hotelname" size="small" style="width: 160px; margin-right: 10px;"
                    placeholder="酒店名称"></el-input>
                <el-button type="primary" size="small" @click="getList_hotel">查询</el-button>
            </div>
            <el-table ref="multipleTable" :data="hotelList" tooltip-effect="dark"
                :header-cell-style="{ background: '#F8FBFF' }" style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column label="酒店首图" width="120" align="center">
                    <template slot-scope="scope">
                        <div>
                            <img :src="scope.row.hotel_pic" style="width: 40px;height: 40px;" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" align="center" label="酒店名称">
                </el-table-column>
            </el-table>
            <div class="Addhotel_dialog_bottom ">
                <div>
                    <span>已选{{ multipleSelection.length }}个酒店</span>
                    <el-button type="primary" size="small" @click="confirm_add_hotel">确认添加</el-button>
                    <el-button size="small" @click="isShow_Addhotel = false">取消</el-button>
                </div>
                <div class="pagination">
                    <pagination :data="pageNation_data" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                    </pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '../../../../../components/PaginationPage/Pagination.vue'

export default {
    components: {
        Pagination,
    },
    data() {
        return {
            editId: null,//编辑id
            title: '创建优惠券',
            form: {
                name: '',//优惠券名称
                type: 1,//优惠劵类型 1现金劵 2满减劵 3 折扣劵
                scope: 0,//使用范围 0通用 1旅游 2 酒店 3商城 4商超
                receive_type: 1,//领取方式 1系统发放 2用户领取
                validity_type: 1,//有效期方式 1领用时间 2 指定时间 3 活动时间
                receive_user_type: 1,//使用类型 1新用户 2会员日 3会员生日 4会员升级
                num: null,//发放数量
                start_date: '',//活动开始时间
                end_date: '',//	活动结束时间
                rule: {
                    amount: null,// 现金金额(现金劵 抵扣金额)
                    discount: null,// 折扣 0-1 （折扣卷 折扣比例 ）
                    full_discount: {// 满减 （满减劵 ）
                        full: null, // 满
                        discount: null,// 减
                    }
                },
                receive_rule: {
                    min_level: 0, // 会员领取最低等级
                    max_take_num: null, // 领用最大数量
                },//	领用规则
                use_rule: {
                    is_sckable: 0,// 是否可以叠加  1表示可以 非0表示不能
                    is_limit_min_price: null,//最低使用金额 
                },//	使用规则
                validity_rule: {
                    after_receive_day: null, // 领用后多少天内有效
                    start_date: null, // 生效时间
                    end_date: null, //  过期时间
                },

                status: 0,//状态 1启用 0禁用
                hotel_ids: null,//酒店id 0表示全部酒店

            },
            status: true,//状态 1启用 0禁用
            times: [],//活动时间
            validity_rule_times: [],//有效期指定时间范围

            multipleSelection: [],//适用酒店表格绑定多选数据
            scope_hotel: 1,// 酒店适用范围 1指定酒店 2全部酒店
            search_hotelname: '',//搜索适用酒店名称
            tableData: [],//选中的适用酒店
            isShow_Addhotel: false,//添加适用酒店对话框
            price_min: 0,
            price_max: 1000,
            checked: true,//是否显示上架酒店
            pageNation_data: {
                total: 0,
                currentPage: 1,
                currentSize: 10,
                small: true
            },

            receive_user_type_options: [//使用类型
                {
                    label: '新用户',
                    value: 1
                }, {
                    label: '会员日',
                    value: 2
                }, {
                    label: '会员生日',
                    value: 3
                }, {
                    label: '会员升级',
                    value: 4
                }
            ],
            type_options: [//优惠劵类型
                {
                    label: '现金券',
                    value: 1
                }, {
                    label: '满减券',
                    value: 2
                }, {
                    label: '折扣券',
                    value: 3
                }
            ],
            scope_options: [//使用范围
                {
                    label: '通用',
                    value: 0
                },
                {
                    label: '电竞酒店',
                    value: 1
                }, {
                    label: '电竞超市',
                    value: 2
                }, {
                    label: '电竞商城',
                    value: 3
                }
            ],
            memberList: [],//会员等级

            hotelList: []//会员等级

        }
    },
    async created() {
        await this.getList_member()
        await this.getList_hotel()

        this.editId = this.$route.query.edit_id;
        if (this.editId) {
            await this.getList_coupon()
            this.title = '编辑优惠券'
        } else {
            this.title = '创建优惠券'
        }
    },
    mounted() {
        // let that  = this
        // this.$nextTick(() => {
        //     // 使用 $nextTick 来等待 Vue 更新 DOM 后再执行下面的代码
        //     // this.$refs.multipleTable.toggleRowSelection(this.multipleSelection, true);
        //     // this.$refs.multipleTable.toggleRowSelection(selectedRowData, true);
        // });
    },
    methods: {

        // 移除
        handle_remove_hotel(item, index) {
            this.tableData.splice(index, 1)

            this.multipleSelection.forEach((Selection_item, Selection_index) => {
                if (Selection_item.hotel_id == item.hotel_id) {
                    this.multipleSelection.splice(Selection_index, 1)
                }
            })
            this.form.hotel_ids.forEach((hotel_ids_item, hotel_ids_index) => {
                if (hotel_ids_item == item.hotel_id) {
                    this.form.hotel_ids.splice(hotel_ids_index, 1)
                }
            })
        },
        // 确认添加适用酒店
        confirm_add_hotel() {
            this.tableData = this.multipleSelection
            this.form.hotel_ids = []
            this.multipleSelection.forEach(item => {
                this.form.hotel_ids.push(item.hotel_id)
            })


            this.isShow_Addhotel = false
        },
        // 创建
        confirm_created() {
            if (!this.checked_form()) {//表单验证
                return
            }
            let action = this.editId ? 'PUT' : 'POST'
            let message = this.editId ? '编辑成功！' : '创建成功！'
            let data = JSON.parse(JSON.stringify(this.form))

            data.start_date = this.times[0]
            data.end_date = this.times[1]
            data.status = this.status ? 1 : 0
            console.log('data.hotel_ids', data.hotel_ids)

            if (this.editId) {
                data.id = this.editId
                console.log('编辑data')
            }
            if (this.scope_hotel == 2) {//全部酒店
                data.hotel_ids = 0
            } else {
                console.log('data.hotel_ids', data.hotel_ids)
                data.hotel_ids = this.form.hotel_ids.join(',')
            }
            console.log('创建优惠券data', data)
            console.log('action', action)

            this.my_request('admin/coupon', data, action).then(res => {
                console.log('创建优惠券结果', res)
                let { code } = res.data
                if (200 == code) {
                    this.$message.success(message)
                    setTimeout(() => {
                        this.backListPage(action)
                    }, 500);
                } else {
                    this.$message.error('创建失败')
                }
            })
        },
        // 返回优惠券列表页面
        backListPage(action) {
            let text = action == 'PUT' ? '编辑成功，是否继续编辑(点击取消将回到优惠券列表)' : '创建成功，是否继续创建(点击取消将回到优惠券列表)'
            this.$confirm(text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (action == 'POST') {
                    this.form = {
                        name: '',//优惠券名称
                        type: 1,//优惠劵类型 1现金劵 2满减劵 3 折扣劵
                        scope: 0,//使用范围 0通用 1旅游 2 酒店 3商城 4商超
                        receive_type: 1,//领取方式 1系统发放 2用户领取
                        validity_type: 1,//有效期方式 1领用时间 2 指定时间 3 活动时间
                        receive_user_type: 1,//使用类型 1新用户 2会员日 3会员生日 4会员升级
                        num: null,//发放数量
                        start_date: '',//活动开始时间
                        end_date: '',//	活动结束时间
                        rule: {
                            amount: null,// 现金金额(现金劵 抵扣金额)
                            discount: null,// 折扣 0-1 （折扣卷 折扣比例 ）
                            full_discount: {// 满减 （满减劵 ）
                                full: null, // 满
                                discount: null,// 减
                            }
                        },
                        receive_rule: {
                            min_level: 1, // 会员领取最低等级
                            max_take_num: null, // 领用最大数量
                        },//	领用规则
                        use_rule: {
                            is_sckable: 0,// 是否可以叠加  1表示可以 非0表示不能
                            is_limit_min_price: null,//最低使用金额 
                        },//	使用规则
                        validity_rule: {
                            after_receive_day: null, // 领用后多少天内有效
                            start_date: null, // 生效时间
                            end_date: null, //  过期时间
                        }
                    }
                    this.times = []//活动时间
                    this.validity_rule_times = []//有效期指定时间范围
                    this.scope_hotel = 1// 酒店适用范围 1指定酒店 2全部酒店
                    this.search_hotelname = ''//搜索适用酒店名称
                    this.tableData = []//选中的适用酒店
                    this.price_min = 0
                    this.price_max = 1000
                    this.checked = true//是否显示上架酒店
                    this.multipleSelection = []//选中的适用酒店
                }
            }).catch(() => {
                this.$router.push({ path: '/EsportsMember/MarketingTools_coupon' })
            });
        },
        // 表单验证
        checked_form() {
            let checked = true

            if (!this.form.name) {
                checked = false
                this.$message.error('请填写优惠券名称')
                return checked
            } else if (this.form.type == 1 && !this.form.rule.amount) {
                this.$message.error('请填写现金额度')
                checked = false
                return checked
            } else if (this.form.type == 2 && !this.form.rule.full_discount.full && !this.form.rule.full_discount.discount) {
                checked = false
                this.$message.error('请填写满减额度')
                return checked
            } else if (this.form.type == 3 && !this.form.rule.discount) {
                checked = false
                this.$message.error('请填写折扣力度')
                return checked
            } else if (!this.form.num) {
                checked = false
                this.$message.error('请填写发行数量')
                return checked
            } else if (!this.form.receive_rule.max_take_num) {
                checked = false
                this.$message.error('请填写可领取数量')
                return checked
            } else if (!this.form.validity_type == 1 && this.form.validity_rule.after_receive_day) {
                checked = false
                this.$message.error('请填写生效天数')
                return checked
            } else if (!this.form.validity_type == 2 && !this.validity_rule_times) {
                checked = false
                this.$message.error('请选择指定时间')
                return checked
            } else if (!this.times) {
                checked = false
                this.$message.error('请选择活动时间')
                return checked
            } else if (!this.form.use_rule.is_limit_min_price) {
                checked = false
                this.$message.error('请填写最低使用金额')
                return checked
            } else if (this.scope_hotel == 1 && !this.form.hotel_ids) {
                checked = false
                this.$message.error('请选择指定适用酒店')
                return checked
            } else {
                return checked
            }
        },

        // 打开添加酒店
        openAddHotel() {
            this.isShow_Addhotel = true
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        // 分页组件事件
        handleSizeChange(val) {
            // 处理每页数量改变的逻辑
            console.log(`每页 ${val} 条`);
            this.pageNation_data.currentSize = val
            // this.getList()
        },
        handleCurrentChange(val) {
            // 处理当前页码改变的逻辑
            console.log(`当前页: ${val}`);
            this.pageNation_data.currentPage = val
            // this.getList()
        },
        // 获取会员列表
        getList_member() {
            let data = {
                type_id: 2,//会员类型 1电商 2酒店 3旅游
            }
            console.log('获取电竞会员等级列表data', data);
            this.my_request('admin/member/grade', data, 'GET').then(res => {
                console.log('获取电竞会员等级列表结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.memberList = data.list
                    this.memberList.unshift(
                        {
                            id: 0,
                            name: '普通'
                        }
                    )

                }
            })
        },

        // 获取优惠券选择酒店列表
        getList_hotel() {
            return new Promise((resolve, reject) => {
                let data = {
                    name: this.search_hotelname,
                    status: this.checked ? 1 : 0,
                    price: this.price_min + '-' + this.price_max,
                    page: this.pageNation_data.currentPage,
                    page_rows: this.pageNation_data.currentSize,
                };

                console.log('获取酒店列表data', data);
                this.my_request('admin/coupon/hotel', data, 'GET').then(res => {
                    console.log('获取酒店列表结果', res);
                    let { code, data } = res.data;
                    if (code === 200) {
                        this.hotelList = data.list;
                        this.pageNation_data.total = data.total;
                        resolve(); // 异步操作完成后调用 resolve()
                    } else {
                        reject(new Error('获取酒店列表失败')); // 如果出现错误，调用 reject()
                    }
                }).catch(error => {
                    reject(error); // 捕获到异常时调用 reject()
                });
            });
        },

        // 获取优惠券详情
        getList_coupon() {
            let data = {
                id: this.editId
            }
            console.log('获取优惠券详情data', data)
            this.my_request('admin/coupon/show', data).then(res => {
                console.log('获取优惠券详情结果', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.form = data
                    this.times = [data.start_date, data.end_date]
                    this.status = data.status == 1 ? true : false
                    if (data.hotel_ids == 0) {
                        this.scope_hotel = 2
                    } else {
                        let arrayIds = data.hotel_ids.split(',')
                        this.form.hotel_ids = arrayIds

                        // console.log(' this.hotelList', this.hotelList)
                        // console.log(' arrayIds', arrayIds)
                        arrayIds.forEach(idItem => {
                            this.hotelList.forEach(hotelItem => {
                                if (idItem == hotelItem.hotel_id) {
                                    this.tableData.push(hotelItem)
                                    this.multipleSelection.push(hotelItem)
                                }
                            })
                        })

                    }


                }

            })
        },
    }
}
</script>

<style lang="scss" scoped>
#MarketingTools_coupon_add {
    .Addhotel_dialog_top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        div {
            margin: 0 10px;
        }

    }

    .Addhotel_dialog_bottom {
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: space-between;

        span {
            margin-right: 50px;
        }
    }

    .MarketingTools_coupon_add_title {
        font-size: 20px;
    }

    .content {
        padding: 20px;

        .content_title {
            margin: 20px 0;
            font-size: 16px;
        }

        .content_content {
            margin: 0 40px;

            .el-form-item {
                margin-bottom: 10px !important;
            }
        }
    }

    .bottom {
        width: 250px;
        margin: 40px auto;
    }
}
</style>